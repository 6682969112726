import type { Getter } from '@core-types/utility'
import scssVariables from '../assets/scss/abstract/_variables-export.module.scss'
import type { ScssBreakpoints } from '@core-types/scss'

export const scssBreakpoint = scssVariables as unknown as ScssBreakpoints

export function getValueByGetter<T>(
    source: T,
    getter: Getter<T> | undefined | null
): T[keyof T] | null {
    if (!getter || typeof source !== 'object' || !source) {
        return null
    }

    if (typeof getter === 'function') {
        return getter(source)
    }

    if (!isKeyInObject(getter, source)) {
        if (typeof getter !== 'string') {
            return null
        }

        const paths = getter.split('.')
        let value = source

        for (const path of paths) {
            if (!isKeyInObject(path, value)) {
                return null
            }

            // @ts-ignore
            value = value[path]
        }

        // @ts-ignore
        return value
    }

    return source[getter]
}

export function isKeyInObject<T extends object>(key: string | number | symbol | keyof T, object: T): key is keyof T {
    return key in object
}

/**
 * Get the result value of a settled promise.
 * @param settled The settled promise
 * @param throwOnError If true, re-throws the captured error if the promise was rejected
 */
export function getPromiseSettledResult<T>(settled: PromiseSettledResult<T>, throwOnError = false) {
    if (throwOnError && settled.status === 'rejected') {
        throw settled.reason
    }
    return settled.status === 'fulfilled' ? settled.value : null
}

export function pascalToKebabCase(str: string) {
    return str
        .replace(/([a-z])([A-Z])/g, '$1-$2')
        .toLowerCase()
}
